import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import Swal from 'sweetalert2'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import socket from '../Components/socket'
const Pay = () => {

  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  if (nodeMode === "development") {
    var baseUrl = beckendLocalApiUrl;
  } else {
    baseUrl = beckendLiveApiUrl;
  }
  
  const access_token = localStorage.getItem('token')

  const { search } = useLocation()
  const history = useHistory()
  useEffect(() => {
    var myHeaders = new Headers()

     const txn_id = search.split("=")[1];
    myHeaders.append('Authorization', `Bearer ${access_token}`)

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    }


    //EKQR
       fetch(baseUrl + `ekqr-request-status?txnId=${
        txn_id.split('?')[0]
      }`,
      requestOptions,
    )
      .then((response) => response.json())
      .then((result) => {
        if (result) {
          Swal.fire({
            title: result.message,
            icon: result.message == 'Payment Failed' ? 'error' : 'success',
            confirmButtonText: 'OK',
          })
          if (result.code == 'PAYMENT_SUCCESS') {
            socket.emit('getprofile')
          }
          history.push('/addcase')
        }
      })
      .catch((error) => console.log('error', error))
  }, [])

    //PhonePay
   /* fetch(
      `https://3.6.217.48/api/phonpay-request-status?txnId=${
        search.split('=')[1]
      }`,
      requestOptions,
    )
      .then((response) => response.json())
      .then((result) => {
        // //console.log(result)
        if (result) {
          Swal.fire({
            title: result.message,
            icon: result.message == 'Payment Failed' ? 'error' : 'success',
            confirmButtonText: 'OK',
          })
          if (result.code == 'PAYMENT_SUCCESS') {
            socket.emit('getprofile')
          }
          history.push('/addcase')
        }
      })
      .catch((error) => //console.log('error', error))
  }, [])*/
  return <div></div>
}

export default Pay
